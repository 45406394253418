<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import { computed, onBeforeUnmount, onMounted } from '#imports'
  import useDraggable, { UseDraggableProps } from '~/composables/Draggable/Draggable'
  import { useGameCinemaStore } from '~/store/GameCinema'
  import { GameEquipmentCategoryId } from '~/model/GameTrouvaille/GameTrouvaille'

  interface Props extends UseBemProps, UseDraggableProps {
    facets?: Array<string>
    draggableId: string
    equipmentCategoryId: GameEquipmentCategoryId
  }

  const gameCinemaStore = useGameCinemaStore()

  const props = defineProps<Props>()
  const { draggingEquipmentCategoryId } = storeToRefs(gameCinemaStore)
  const { rootEl, isDragging, handleDragStart, handleDragEnd, mountDragImage, unmountDragImage } =
    useDraggable(props)
  const { bemFacets, bemAdd } = useBem('c-cinema-draggable', props, {})

  const rootClasses = computed<Array<string>>(() => {
    return [...bemFacets.value, bemAdd(isDragging.value ? 'is-dragging' : '')]
  })

  const onDragStart = (e: DragEvent) => {
    draggingEquipmentCategoryId.value = props.equipmentCategoryId
    handleDragStart(e)
  }

  const onDragEnd = (e: DragEvent) => {
    draggingEquipmentCategoryId.value = null
    handleDragEnd(e)
  }

  onMounted(() => {
    mountDragImage(80, 80)
  })

  onBeforeUnmount(() => {
    unmountDragImage()
  })
</script>

<template>
  <div
    ref="rootEl"
    :class="rootClasses"
    class="c-cinema-draggable"
    draggable="true"
    @dragend="onDragEnd($event)"
    @dragstart="onDragStart($event)"
  >
    <div class="c-cinema-draggable__inner">
      <slot name="content" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:map';
  @use 'sass:math';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/transition' as trs;
  @use '/components/Ui/UiIcon/UiIcon' as UiIcon;

  .c-cinema-draggable {
    pointer-events: auto;
    position: relative;
    cursor: grab;
    border: 1px solid transparent;
    background-color: col.$monochrome-black;
  }

  .c-cinema-draggable--is-dragging {
    cursor: grabbing;
  }

  .c-cinema-draggable--in-staging-zone {
    border: 1px solid col.$variant-red-dark;
  }

  .c-cinema-draggable--in-drop-zone {
    border: 1px solid col.$variant-cyan-light;
  }

  .c-cinema-draggable__inner {
    pointer-events: none;
    position: relative;
  }
</style>
